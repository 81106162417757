

require('./bootstrap');


let observer = new IntersectionObserver(function (observables) {
  observables.forEach(function (observable) {
    console.log(observable.intersectionRatio) // L'élément devient visible
    if (observable.intersectionRatio > 0.5) {

      console.log('observed !!!')
      observable.target.classList.remove('not-visible')
      observable.target.classList.add('visible')
      // observable.target.classList.add('border', 'border-4', 'border-danger')
      observer.unobserve(observable.target)
    }
  })

}, {
  threshold: [0.5] // permet d'indiquer la zone à partir de laquelle l'élément devient 'visible'
});

// On observe nos éléments
let items = document.querySelectorAll('.appear')
items.forEach(function (item) {
  item.classList.add('not-visible')
  observer.observe(item)
})


// import { createApp, h } from 'vue';
// import { createInertiaApp } from '@inertiajs/inertia-vue3';
// import { InertiaProgress } from '@inertiajs/progress';

// const el = document.getElementById('app');

// createInertiaApp({
//   resolve: (name) => require(`./Pages/${name}`),
//   setup({ el, app, props, plugin }) {
//     createApp({ render: () => h(app, props) })
//       .mixin({ methods: { route } })
//       .use(plugin)
//       .mount(el);
//   },
// });

import Swiper, { Navigation, Autoplay } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
// import 'swiper/css/pagination';

// init Swiper:
const swiper = new Swiper('.swiper', {
  // configure Swiper to use modules
  modules: [Navigation, Autoplay],
  // direction: 'horizontal',

  loop: true,
  loopedSlides: 3,
  slidesPerView: 3,
  autoplay: true,
  autoHeight: true,
  // centeredSlides: true,
  // autoplay: {
  //   delay: 1500,
  //   pauseOnMouseEnter: true,
  // },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
});


console.log("front here !")

var myCarousel = document.querySelector('#myCarousel')
if (myCarousel) {
  var carousel = new bootstrap.Carousel(myCarousel, {
    interval: 2000,
    wrap: false
  })
}


var toastEl = document.querySelector('#js-cookie-consent-toast')
console.log('toastEl', toastEl)
if (toastEl) {
  var toast = new bootstrap.Toast(toastEl, {
    animation: true,
    autohide: false,
    delay: 1000
  })
  toast.show()

}


 

